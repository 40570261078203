<template>
  <v-app dense fixed>
    <div
      v-bind:class="{
        bgSvg: !$vuetify.theme.dark,
        bgSvgDark: $vuetify.theme.dark,
      }"
    >
      <v-flex
        class="backDropper"
        v-bind:style="{
          'color:rgb(255, 255, 255,0.5)': !$vuetify.theme.dark,
          'color:rgb(0,0,0)': $vuetify.theme.dark,
        }"
      >
        <v-dialog
          v-model="mobileNoiDialog"
          scrollable
          fullscreen
          style="{scrollbar-width:none}"
          persistent
          v-if="$vuetify.breakpoint.mobile"
        >
          <v-card>
            <notiDialog
           
              :msgList="currentNoti"
              :msgId="msgId"
              @clicked="onClickChild"
          /></v-card>
        </v-dialog>
        <v-dialog
          v-model="dialogProgress"
          persistent
          max-width="50vw"
          class="mt-5 primary"
          style="border:none !important"
        >
          <v-card class="pa-4">
            <div class="text-center">
              {{ progressMessages }}
              <v-progress-circular
                v-if="progressMessages !== 'No User Found'"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="sendMessageDialog"
          max-width="70vw"
          class=" primary"
          style="border:none !important"
        >
          <v-card class="pa-5" max-width="70 vw" elevation="0">
          <validation-observer v-slot="notificationErrors">
          <v-form ref="notificationForms">
          <v-row class="ma-0 ml-2">
              <v-col cols="12" md="4">
              
              <validation-provider rules="required" v-slot="{errors}" name="Thuchhuah thawnna tur District">
               <v-select
                  class=" "
                  :error-messages="errors"
                  required
                  label="District"
                  :items="District"
                  :disabled="userInfo.nulm !== 'SMMU'"
                  outlined
                  v-model="userDistrict"
                ></v-select
              >
              </validation-provider>
               </v-col>
              <v-col cols="12" md="4">
              <validation-provider rules="required" v-slot="{errors}" name="Thuchhuah thawnna tur District">

                <v-select
                :error-messages="errors"
                  class=""
                  label="Department"
                  :items="userType"
                  outlined
                  required
                  v-model="userDepartment"
                ></v-select>
              </validation-provider>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  v-model="name"
                  label="Name"
                  hint="Department pumpui thawn dawn chuan dah awl tur"
                ></v-text-field> </v-col
            ></v-row>
            <v-row class="mx-3">
              <v-col>
              <validation-provider rules="required" v-slot="{errors}" name="Thuchhuah thawnna tur District">
             
                <v-text-field
                :error-messages="errors"
                  outlined
                  v-model="title"
                  label="Title"
                  hint="Thuchhuah thupui tur"
                ></v-text-field>
              </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mx-3">
              <v-col>
              <validation-provider rules="required" v-slot="{errors}" name="Thuchhuah thawnna tur District">

                <v-text-field
                  outlined
                  v-model="notification"
                  :error-messages="errors"
                  grow
                  label="Body"
                  hint="Thuchhuah sawifiahna"
                  :single-line="false"
                ></v-text-field>
              </validation-provider>
              </v-col>
            </v-row>
            <v-col cols="">
              <div class="text-center">
                <v-btn
                  align-center
                  style="overflow:hidden"
                  class="mx-1"
                  color="primary buttonGlass"
                  :disabled="!notificationErrors.valid"
                  depressed
                  @click="submit()"
                  >SUBMIT</v-btn
                >
              </div>
            </v-col>
          </v-form>
          
          </validation-observer>
            
          </v-card>
        </v-dialog>
        <v-dialog v-model="showUrl" class="" :max-width="width">
          <div
            max-width="80vw"
            style="position: absolute;
            bottom:10vh;
           
            right:1vw;
            z-index:4;"
          >
            <v-slider
              v-model="width"
              class="mx-9 "
              min="400"
              max="1600"
              step="1"
              max-width="90vw"
            ></v-slider>
          </div>

          <v-card @contextmenu="show" :max-width="width">
            <v-skeleton-loader
              class="mx-auto man"
              v-if="skeletonOn"
              type="card-avatar, article"
            ></v-skeleton-loader>
            <v-img
              contain
              @error="onImageError"
              :v-show="!skeletonOn"
              :src="imgUrl"
              @loadstart="skeletonOn = true"
              @load="skeletonOn = false"
            ></v-img>
            <v-menu
              class="ma-0 pa-0"
              v-model="showMenu"
              :position-x="x"
              :position-y="y"
              absolute
              offset-y
            >
              <v-list>
                <v-list-item
                  ><a
                    :href="imgUrl"
                    target="_blank"
                    download="new.jpg"
                    class="text--primary"
                    style="text-decoration:none;"
                    ><v-list-item-title
                      >Open Image In new Tab</v-list-item-title
                    ></a
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card>
        </v-dialog>
        <v-flex id="main" class="pa-4">
          <v-row>
            <v-col class="ma-0 pr-0" cols="12" md="4">
              <v-card elevation="4" height="88vh" class="text-center ">
                <v-app-bar
                  mini
                  dense
                  elevation="0"
                  color="primary"
                  class="icon--red white--text"
                >
                  <v-spacer>Notifications</v-spacer>
                </v-app-bar>
              <v-tooltip top color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="['CMMU', 'SMMU'].includes(userInfo.nulm)"
                  color="pink"
                  fab
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                  absolute
                  bottom
                  right
                  class="mt-n1 mb-15"
                  elevation="12"
                  v-on:click="sendMessageDialog = !sendMessageDialog"
                >
                  <v-icon>fas fa-plus</v-icon>
                </v-btn>
              </template>
              <div>
              Thuchhuah thar siamna
              </div>
              </v-tooltip>
              
                <div
                  class="ma-0 pb-3 pt-1 px-4"
                  style="height:80vh;
    overflow:scroll;overflow-x: hidden;"
                >
                  <v-list three-line active id="main2" >
                    <v-list-item-group v-model="selected" class="my-2">
                      <template v-for="(item, index) in userNotifications">
                        <v-list-item style="border:1px solid grey;margin-bottom:4px;border-radius:4px;"
                          :key="index"
                          v-on:click="showMessageInit(item)"
                        >
                          <template>
                            <v-list-item-content class="text-start">
                              <v-list-item-title>
                                {{ item.title }}
                              </v-list-item-title>
                              <v-list-item-subtitle v-if="item.notification">
                                {{ item.notification }}
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="item.createdAt">
                                {{ tsTodate(item.createdAt) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-card>
            </v-col>
            <v-col
              v-if="
                userNotifications.length > 0 &&
                  msgId &&
                  !$vuetify.breakpoint.mobile
              "
              cols="8"
            >
              <notiDialog :msgList="currentNoti" :msgId="msgId" />
            </v-col> </v-row
        ></v-flex>
      </v-flex>
    </div>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import {required} from 'vee-validate/dist/rules'
import { ValidationObserver,ValidationProvider,setInteractionMode,extend} from 'vee-validate'
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} a ruak theilo'
})
import notiDialog from "../routes/NotiDialog.vue";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
export default {
  components: {
    notiDialog,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      mobileNoiDialog: false,
      limit: [],
      progressMessages: "",
      x: 0,
      y: 0,
      showMenu: false,
      width: "200",
      dialogProgress: false,
      title: "",
      notification: "",
      name: "",
      userType: ["SHG", "ALF", "CLF", "SMA", "SMC", "SEP", "SUSV", "ESTP"],
      fileProg: [],
      District: [
        "Aizawl",
        "Kolasib",
        "Lawngtlai",
        "Lunglei",
        "Mamit",
        "Saiha ",
        "Serchhip",
        "Champhai",
        "Hnahthial",
        "Khawzawl",
        "Saitual",
      ],
      arr: [],
      fileUpload: [],
      userTableList: false,
      lastName: "",
      userSearchName: "",
      headers: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
          text: "ID",
          align: "start",
          value: "id",
        },
      ],
      userDistrict: "",
      userDepartment: "",
      usersQuery: [],
      selected: null,
      notiList: [],
      msgId: "",
      msgSender: "",
      msgReciever: "",
      msgBody: "",
      newMessage: "",
      sendMessageDialog: false,
      sendMessagesId: "",
      sendMessagesName: "",
      sendUserMessagesMsg: "",
      showUrl: false,
      imgUrl: "",
      skeletonOn: false,
    };
  },

  methods: {
    onClickChild(input) {
      this.mobileNoiDialog = input;
    },
    showMessageInit(input) {
      if (this.$vuetify.breakpoint.mobile) {
        this.mobileNoiDialog = true;
      }
      this.msgId = input.id;
    },
    attachTrigger() {
      let trig = document.getElementById("fileInputSel");
      trig.click();
    },
    showMessage(id, list) {
      var sorted = list.filter((e) => e.id == id);
      if (id != null) {
        return sorted[0]?.msg;
      } else {
        return [];
      }
    },

    onImageError(msg) {
      window.open(msg, "_blank");
      this.showUrl = false;
    },
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    async submit() {
    
      this.sendMessageDialog = false;
      this.dialogProgress = true;
      this.progressMessages = "Sending Your Message Please Wait";
      let userData,
        name = this.userInfo.name,
        msg = {
          createdAt: firebase.firestore.Timestamp.now(),
          msgUser: this.notification,
          id: firebase.auth().currentUser.uid,
          name: this.userInfo.name,
        };
      userData = {
        senderId: firebase.auth().currentUser.uid,
        senderName: name,
        senderNulm: this.userInfo.nulm,
        title: this.title,
        notification: this.notification,
        createdAt: firebase.firestore.Timestamp.now(),
        msg: [msg],
      };
      let userPath;
      switch (this.userDepartment) {
        case "ALF":
        case "CLF":
        case "SHG":
          userPath = "smnid";
          break;
        case "SMA":
        case "SMC":
          userPath = "suh";
          break;
        case "SEP":
        case "SUSV":
        case "ESTP":
          userPath = this.userDepartment.toLowerCase();
          break;
        default:
          userPath = this.userNulm.toLowerCase();
      }
      let temp = `${userPath}_${this.userDistrict.toLowerCase()}`;

      userData["userPath"] = temp;
     
      if (this.name) {
        this.progressMessages = "Retrieving User";

        await firebase
          .firestore()
          .collection(temp.trim())
          .where("name", "==", this.name.toUpperCase().trim())
          .get()
          .then(async (doc) => {
            if (!doc.empty) {
              this.progressMessages = "User Found";
              doc.forEach(async (e) => {
                (userData["recipientId"] = e.id),
                  (userData["recipientName"] = e.data().name);
                this.progressMessages = "User Found Sending Message";
              });
              firebase
                .firestore()
                .collection("notifications")
                .add(userData)
                .then(async (doc) => {
                  this.progressMessages = "User Message Send";
                  firebase
                    .firestore()
                    .collection(temp.trim())
                    .doc(userData["recipientId"])
                    .update({
                      noti: firebase.firestore.FieldValue.arrayUnion(doc.id),
                    })
                    .then(async () => {
                      firebase
                        .firestore()
                        .collection(localStorage.getItem("userDb"))
                        .doc(this.userInfo.id)
                        .update({
                          noti: firebase.firestore.FieldValue.arrayUnion(
                            doc.id
                          ),
                        })
                        .then(()=>{
                            this.$refs.notificationForms.reset()
                           this.dialogProgress = false
                        }
                          
                         );
                    });
                });
            } else {
              this.progressMessages = "No User Found";
              setTimeout(() => {
                this.dialogProgress = false;
                  this.$refs.notificationForms.reset()
              }, 2000);
            }
          })
          .catch((err) => {
            this.$store.commit("changeError", err);
          });
      } else {
        firebase
          .firestore()
          .collection("notifications")
          .add(userData)
          .then((doc) => {
            const idDOc = doc.id;
            {
              firebase
                .firestore()
                .collection(`notifications`)
                .doc(
                  `${this.userDepartment.toLowerCase()}_${this.userDistrict.toLowerCase()}`
                )
                .update({
                  noti: firebase.firestore.FieldValue.arrayUnion({
                    id: doc.id,
                    noti: this.title,
                    createdAt:firebase.firestore.Timestamp.now(),
                    body:this.notification
                  }),
                })
                .catch((err) => {
                  if (err.code === "not-found") {
                    firebase
                      .firestore()
                      .collection(`notifications`)
                      .doc(
                        `${this.userDepartment.toLowerCase()}_${this.userDistrict.toLowerCase()}`
                      )
                      .set({
                        noti: [
                          {
                            id: doc.id,
                            noti: this.title,
                            createdAt:firebase.firestore.Timestamp.now(),
                            body:this.notification
                          },
                        ],
                      });
                  }
                })
                .then(() => {
                  if (!this.userInfo.noti)
                    firebase
                      .firestore()
                      .collection(localStorage.getItem("userDb"))
                      .doc(firebase.auth().currentUser.uid)
                      .update({
                        noti: [idDOc],
                      });
                  else {
                    firebase
                      .firestore()
                      .collection(localStorage.getItem("userDb"))
                      .doc(firebase.auth().currentUser.uid)
                      .update({
                        noti: firebase.firestore.FieldValue.arrayUnion(idDOc),
                      });
                  }
                    this.$refs.notificationForms.reset()
                  this.dialogProgress = false;
                });
            }
          });
      }
    },
    viewUrl(url) {
      this.showUrl = true;
      this.imgUrl = url;
    },
    tsTodate(dateInput) {
      return dateInput
        ?.toDate()
        .toString()
        .replace("GMT+0530 (India Standard Time)", "");
    },
    notMyId(list) {
      return list.filter((e) => e != firebase.auth().currentUser.uid)[0];
    },

    getLayout(sender) {
      if (sender == firebase.auth().currentUser.uid) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {

    this.userDistrict = localStorage.getItem("district")[0].toUpperCase().concat(localStorage.getItem("district").substring(1))

    // this.limit = JSON.parse(localStorage.getItem("clearNoti"));

    // if (this.limit)
    //   if (this.limit.length ?? 0 > 10)
    //     window.alert(
    //       "You have too many notifications please reply to some of them or delete them"
    //     );

    setTimeout(() => {
      var content = document.getElementById("content");
      if (content) content.scrollTop = content.scrollHeight;
    }, 250);
    let hiddenNoti = this.userInfo.hiddenNoti;
    let groupNoti = this.$store.state["userNotificationsLists"];
    groupNoti = groupNoti.noti;
    let creation = [];

    if (groupNoti) await groupNoti.forEach(async (e) => {
      creation.push(e.id);
    });
    let final
    if (hiddenNoti)  final = await hiddenNoti.filter((e) => creation.includes(e));
    if(final) if (final.length > 0) {
      firebase
        .firestore()
        .collection(localStorage.getItem("userDb"))
        .doc(this.userInfo.id)
        .update({
          hiddenNoti: final,
        })
        .catch((err) => this.$store.commit("changeError", err));
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.id != null) {
        vm.msgId = to.params.id;
      } else {
        vm.msgId = null;
      }
    });
  },
  onRouteUpdate(to, from, next) {
    next((vm) => {
      if (to.params.id != null) {
        vm.msgId = to.params.id;
      } else {
        vm.msgId = null;
      }
    });
  },
  beforeDestroy() {
    //set all notification to seen here
  },

  computed: {
    currentNoti() {
      return this.userNotifications.filter((e) => e.id === this.msgId)[0];
    },
    ...mapState(["userInfo", "userNotifications"]),
  },
};
</script>
<style scoped>
.scroll-target {
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.bgSvg {
  min-height: 100vh;
  max-height: 100vh;
  background-color: var(--v-secondary-darken--20);
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='111' height='111' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23cacaca' fill-opacity='0.46'%3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='0.46'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.bgSvgDark {
  min-height: 100vh;
  max-height: 100vh;
  background-color: #07070748;
  background-repeat: repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 200 200'%3E%3Cdefs%3E%3ClinearGradient id='a' gradientUnits='userSpaceOnUse' x1='100' y1='33' x2='100' y2='-3'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='100' y1='135' x2='100' y2='97'%3E%3Cstop offset='0' stop-color='%23000' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23000' stop-opacity='1'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cg fill='%23060606' %3E%3Crect x='100' width='100' height='100'/%3E%3Crect y='100' width='100' height='100'/%3E%3C/g%3E%3Cg fill-opacity='1'%3E%3Cpolygon fill='url(%23a)' points='100 30 0 0 200 0'/%3E%3Cpolygon fill='url(%23b)' points='100 100 0 130 0 100 200 100 200 130'/%3E%3C/g%3E%3C/svg%3E");
}
.backDropper {
  min-height: 100vh;
  max-height: 100vh;
  backdrop-filter: blur(15px);
}
.buttonGlass::before{
position: absolute;
left: 0%;
width: 20%;
backdrop-filter: blur(2px);
transform: skewX(-40deg) translateX(-3rem);
background-color: white;
opacity: 0.4 !important;
z-index: 2;
transition: 0.4s ease-out;
border-radius: none !important;
}
.buttonGlass:hover::before{
left: 100%;
transform: skewX(-40deg) translateX(1rem);
}
</style>
