<template>
  <div id="account-info ma-0 pa-0">
    <v-dialog
      v-model="dialogProgress"
      persistent
      max-width="50vw"
      class="mt-5 primary"
      style="border:none !important"
    >
      <v-card class="pa-4">
        <div class="text-center">
          {{ progressMessages }}
          <v-progress-circular
            v-if="progressMessages !== 'No User Found'"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showUrl" class="" :max-width="width">
      <div
        max-width="80vw"
        style="position: absolute;
            bottom:10vh;
           
            right:1vw;
            z-index:4;"
      >
        <v-slider
          v-model="width"
          class="mx-9 "
          min="400"
          max="1600"
          step="1"
          max-width="90vw"
        ></v-slider>
      </div>

      <v-card @contextmenu="show" :max-width="width">
        <v-skeleton-loader
          class="mx-auto man"
          v-if="skeletonOn"
          type="card-avatar, article"
        ></v-skeleton-loader>
        <v-img
          contain
          @error="onImageError"
          :v-show="!skeletonOn"
          :src="imgUrl"
          @loadstart="skeletonOn = true"
          @load="skeletonOn = false"
        ></v-img>
        <v-menu
          class="ma-0 pa-0"
          v-model="showMenu"
          :position-x="x"
          :position-y="y"
          absolute
          offset-y
        >
          <v-list>
            <v-list-item
              ><a
                :href="imgUrl"
                target="_blank"
                download="new.jpg"
                class="text--primary"
                style="text-decoration:none;"
                ><v-list-item-title>Open Image In new Tab</v-list-item-title></a
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card>
    </v-dialog>
    <v-card
      :min-height="heightOf"
      :max-height="heightOf"
      elevation="4"
      class="lighten-1 white--text overflow-y-auto   pa-0"
      :class="{ 'mx-0': $vuetify.breakpoint.mobile }"
      style="overflow-y:scroll !important;overflow-x:hidden"
    >
      <v-app-bar
        absolute
        mini
        dense
        elevation="0"
        color="primary"
        class="icon--red white--text"
      >
      {{msgList.title}}
        <v-spacer></v-spacer>
        <v-tooltip bottom color="error" v-if="['SMMU','CMMU'].includes(this.$store.state.userInfo.nulm)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="primary" icon v-bind="attrs" v-on="on"
              ><v-icon color="white" @click.prevent="deleteMessage(msgId)"
                >fas fa-trash</v-icon
              ></v-btn
            ></template
          >
          <span>Message delete-na</span>
        </v-tooltip>

        <v-btn
          class="primary"
          icon
          v-if="$vuetify.breakpoint.mobile"
          @click.prevent="close()"
          ><v-icon color="white">fas fa-times-circle</v-icon></v-btn
        ></v-app-bar
      >

      <Grievances
        v-if="typeof msgController === 'string'"
        :nulmSelect="msgList.senderNulm"
        class="mt-10 pt-10 px-5"
        :sep="JSON.parse(msgList.msg)"
        :estp="JSON.parse(msgList.msg)"
        :susv="JSON.parse(msgList.msg)"
      ></Grievances>
    
      <div
        v-if="typeof msgController !== 'string'"
        class="mt-9 secondary"
        id="content"
        style="height:70vh;
    overflow:scroll;overflow-x: hidden; scrollTop:123"
      >
        <v-list
          dense
          id="scroll-content"
          class="secondary"
          v-if="typeof msgController !== 'string'"
        >
          <v-list-item
            v-for="(message, index) in msgController"
            v-bind:key="index"
            align-item="end"
          >
            <v-list-item-content>
              <v-row>
                <v-col
                  cols=""
                  class="d-flex justify-start"
                  :class="{ ' justify-end': getLayout(message.id) }"
                >
                  <v-card
                    min-width="30%"
                    max-width="80%"
                    elevation="1"
                    outlined
                    style="word-wrap: break-word"
                    :class="{
                      'rounded-tl-xl rounded-bl-xl rounded-br-xl darken-2': getLayout(
                        message.id
                      ),
                      'rounded-tr-xl rounded-bl-xl rounded-br-xl': !getLayout(
                        message.id
                      )
                    }"
                  >
                    <v-card-text class="pt-2 pb-0 px-5">
                      <div class="mb-0">From:{{ message.name }}</div>
                      <v-divider color="grey"></v-divider>
                      <p class="text-h6 text--primary mb-n1">
                        {{ message.msgUser }}
                      </p>

                      <v-card-actions>
                        <div v-for="url in message.url" :key="url">
                          <v-btn icon @click="viewUrl(url)">
                            <v-icon>fas fa-paperclip</v-icon>
                          </v-btn>
                        </div>
                        <div class="text-end ">
                          {{ tsTodate(message.createdAt) }}
                        </div>
                      </v-card-actions>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item></v-list
        >
      </div>
      <v-card elevation="0" class="ma-4 pa-0" >
        <v-row justify="end" v-if="typeof msgController !== 'string'">
          <v-col cols="1" md="1" class="text-end">
            <v-tooltip top color="error">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  icon
                  @click.prevent="attachTrigger()"
                  class="ma-0 pa-0"
                >
                  <v-icon class="ma-0 pa-0">
                    fas fa-paperclip
                  </v-icon></v-btn
                >
              </template>
              <span>File Upload-na</span>
            </v-tooltip>
            <v-file-input
              id="fileInputSel"
              v-show="false"
              reverse
              counter
              hide-input
              multiple
              show-size
              append-outer-icon="fas fa-paperclip"
              label="Attachemnt(I file upload tur thlang rawh)"
              outline
              dense
              v-model="fileUpload"
            ></v-file-input>
          </v-col>
          <v-col class="pa-0 ma-0" background-color="primary">
            <v-textarea
              class=" red--text pa-0 mx-2"
              label="Enter new Message"
              v-model="newMessage"
              rows="1"
              auto-grow
            >
            </v-textarea>
          </v-col>
          <v-col cols="2" class="text-center">
       
            <v-btn
              class="primary buttonGlass"
              @click="submitMessage(msgId, newMessage)"
              >Send</v-btn
            ></v-col
          >
        </v-row>
        <v-row v-if="typeof msgController === 'string'">
          <v-col class="text-center">
            <v-btn
              text
              class="primary"
              @click="grievancesApproved(msgList)"
              center
              >Approve</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import Grievances from '../routes/UserGrievances.vue'
import { mapState } from 'vuex'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
export default {
  props: ['msgList', 'msgId'],
  components: {
    Grievances
  },
  data () {
    return {
      newMessage: '',
      x: 0,
      y: 0,
      showMenu: false,
      width: '200',
      showUrl: false,
      arr: [],
      fileUpload: [],
      progressMessages: '',
      dialogProgress: '',
      fileProg: '',
      skeletonOn: false,
      imgUrl: ''
    }
  },
  methods: {
    async grievancesApproved (data) {
      
      let numberFiltered = [data.senderNumber]
      this.dialogProgress = true
      this.progressMessages = 'Notifying Users'
      let api
      await Object.keys(this.userInfo).forEach(async e => {
        if (e.startsWith('fie')) {
          api = e
        }
      })
      const apiKey = Buffer.from(this.userInfo[api], 'base64').toString('ascii')
      const message = `UD&PA atanga thuchhuah: I complaint thehluh hi CMMU-in a en tawh a. Thlalak leh thil tul dangte upload nghal tura tih i ni e.`

      var url = 'https://api.textlocal.in/send/?'
      var newest = `${url}apiKey=${apiKey}&numbers=${numberFiltered.toString()}&sender=UDnPA&message=${encodeURIComponent(
        message
      )}`
      axios
        .post(newest)
        .then(response => {
          if (response) {
            if (response.data.errors) this.$store = response.data.errors
        
            let resRecipient = []

            response.data.messages.forEach(element =>
              resRecipient.push(element)
            )
            resRecipient = resRecipient.map(element => element.recipient)
            this.dialogMessage = `${response.data.num_messages}message(s) send ${response.data.status} with cost ${response.data.cost} and Batch ID = ${response.data.batch_id} by ${response.data.message.sender}. Remaining balance is ${response.data.balance}`

            firebase
              .firestore()
              .collection('smsSend')
              .doc('smsDetails')
              .update({
                smsList: firebase.firestore.FieldValue.arrayUnion(response.data)
              })
              .then(() => {
              let arrRemove 
             
                this.userNotificationsLists.noti.forEach(e => {
                  if(e.id === data.id) arrRemove=e
                })
             
                firebase.firestore().collection("notifications").doc(`cmmu_${data.userPath.split("_")[1].toLowerCase()}`).get().then((doc)=>{
                  console.log(doc.data())
                })
                firebase.firestore().collection("notifications").doc(`cmmu_${data.userPath.split("_")[1].toLowerCase()}`).update({
                  noti:firebase.firestore.FieldValue.arrayRemove(arrRemove)
                })
              })
              .catch(error => {
                this.$store.commit('changeError', error)
              })
          }
        })
        .catch(function (error) {
      error
        })
    },
    deleteMessageTemp (msgId) {
      let filteredMessage = this.userNotifications.filter(e => {
        return e.id != msgId
      })
      this.$store.state.userNotifications = filteredMessage
    },
    deleteMessage (msgId) {
      if (
        this.msgList.recipientId === this.userInfo.id ||
        this.msgList.senderId === this.userInfo.id
      ) {
        let noti = this.userInfo.noti

        if (noti && noti.includes(msgId)) {
          firebase
            .firestore()
            .collection(localStorage.getItem('userDb'))
            .doc(this.userInfo.id)
            .update({ noti: firebase.firestore.FieldValue.arrayRemove(msgId) })
            .then(this.deleteMessageTemp(msgId))
            .catch(err => this.$store.commit('changeError', err.msg))
        }
      } else {
        firebase
          .firestore()
          .collection(localStorage.getItem('userDb'))
          .doc(this.userInfo.id)
          .update({
            hiddenNoti: firebase.firestore.FieldValue.arrayUnion(msgId)
          })
          .then(() => {
            window.alert('Message deleted from your subscription')
            this.deleteMessageTemp(msgId)
          })
          .catch(err => this.$store.commit('changeError', err.msg))

        // let groupNoti = this.$store.state["userNotificationsLists"];

        // if (groupNoti.length > 0) {
        //   groupNoti = groupNoti.noti;

        //   groupNoti.forEach(async (e) => {
        //     if (e.id === msgId) {
        //        axios
        //     }
        //   });
        // }
      }
    },
    close () {
      try {
        this.$emit('clicked', false)
      } catch (err) {
        this.$store.commit('changeError', err)
      }
    },
    onImageError (msg) {
      window.open(msg, '_blank')
      this.showUrl = false
    },
    show (e) {
      e.preventDefault()
      this.showMenu = false
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showMenu = true
      })
    },
    submitMessage (id, msg) {
      this.arr = []

      if (this.fileUpload.length > 0) {
        this.progressMessages = 'Uploading Your Files Please Wait'
        this.dialogProgress = true
        this.fileProg = new Array(this.fileUpload.length)
        for (let [i, v] of this.fileUpload.entries()) {
          v
          this.fileProg[i] = 0
          let ref = firebase
            .storage()
            .ref()
            .child('test')
          let e = this.fileUpload[i]
          ref = ref.child(
            e.name
              .toString()
              .split('.')[0]
              .concat('.', e.type.toString().split('/')[1])
          )

          // await new Promise((resolve) => setTimeout(resolve, 5000));
          let finalRef = ref.put(this.fileUpload[i])
          this.fileProg[i] = 0
          finalRef.on(
            'state_changed',
            async snapshot => {
              var progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              this.progress = Math.floor(progress)
                .toString()
                .concat('-', i)
              this.fileProg[i] = String(i) + '-' + progress
            },
            error => {
              this.$store.commit('changeError', error)
            },
            () => {
              finalRef.snapshot.ref.getDownloadURL().then(async res => {
                this.arr.push(res)
                if (this.arr.length === this.fileUpload.length) {
                  this.sendMessage(id, msg)
                }
              })
            }
          )
        }
      } else {
        this.sendMessage(id, msg)
      }
    },
    sendMessage (id, msg) {
      let colRec
      if (
        this.$store.state.userInfo.nulm === 'SMMU' ||
        this.$store.state.userInfo.nulm === 'CMMU'
      ) {
        colRec = this.$store.state.userInfo.nulm.toLowerCase()
      } else {
        colRec = `${this.$store.state.userInfo.nulm
          .toLowerCase()
          .replace(
            '&',
            'n'
          )}_${this.$store.state.userInfo.district.toLowerCase()}`
      }
      let tempUpdate
      let msgData = this.msgList

      let targetPush

      if (msgData?.senderId != this.userInfo.id) {
        //i am nt sender
        if (msgData?.recipientId === this.userInfo.id) {
          // i am recepient it is pm
          tempUpdate = {
            noti: firebase.firestore.FieldValue.arrayRemove(id)
          }
        } else if (!msgData?.recipientId) {
          //i am rec it is not pm
          tempUpdate = {
            hiddenNoti: firebase.firestore.FieldValue.arrayUnion(id)
          }
        }

        targetPush = firebase
          .firestore()
          .collection(msgData.senderNulm.toLowerCase())
          .doc(msgData.senderId)
          .update({
            noti: firebase.firestore.FieldValue.arrayUnion(msgData.id)
          })
          .catch(err => this.$store.commit('changeError', err))
      } else if (msgData?.senderId === this.userInfo.id) {
        
        tempUpdate = {
          noti: firebase.firestore.FieldValue.arrayRemove(id)
        }
        if (msgData?.recipientId) {
          targetPush = firebase
            .firestore()
            .collection(msgData.userPath)
            .doc(msgData.recipientId)
            .update({
              noti: firebase.firestore.FieldValue.arrayUnion(msgData.id)
            })
            .catch(err => this.$store.commit('changeError', err))
        }
      }
      firebase
        .firestore()
        .collection('notifications')
        .doc(id)
        .update({
          msg: firebase.firestore.FieldValue.arrayUnion({
            msgUser: msg,
            createdAt: firebase.firestore.Timestamp.now(),
            id: firebase.auth().currentUser.uid,
            name: this.$store.state.userInfo.name,
            url: this.arr
          })
        })
        .then(async ()=>{
            if(msgData?.senderId != this.userInfo.id){
               firebase
            .firestore()
            .collection(colRec)
            .doc(firebase.auth().currentUser.uid)
            .update(tempUpdate)
            .then(
              targetPush,
              (this.dialogProgress = false),
              (this.fileUpload = []),
              (this.newMessage = '')
            ),

          setTimeout(() => {
            this.newMessage = ""
            var content = document.getElementById('content')
            content.scrollTop = content.scrollHeight - 10
          }, 250)
            }
           
        }
         
        )
           setTimeout(() => {
            this.newMessage = ""
            var content = document.getElementById('content')
            content.scrollTop = content.scrollHeight - 10
          }, 250)
    },
    attachTrigger () {
      let trig = document.getElementById('fileInputSel')
      trig.click()
    },
    viewUrl (url) {
      this.showUrl = true
      this.imgUrl = url
    },
    tsTodate (dateInput) {
      return dateInput
        ?.toDate()
        .toString()
        .replace('GMT+0530 (India Standard Time)', '')
    },

    getLayout (sender) {
      if (sender == this.$store.state.userInfo.id) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
   
  },
  computed: {
    msgController () {
      let retArr = []

      if (['SMMU', 'CMMU'].includes(this.userInfo.nulm))
        retArr = this.msgList?.msg
      else
        this.msgList?.msg.forEach(e => {
          if (
            this.msgList.senderId &&
            (e.id === this.msgList.senderId || e.id === this.userInfo.id)
          )
            retArr.push(e)
        })

      return retArr
    },

    heightOf () {
      if (this.$vuetify.breakpoint.mobile) {
        return '100vh'
      } else {
        return '88vh'
      }
    },
    ...mapState(['userInfo', 'userNotifications','userNotificationsLists'])
  }
}
</script>
<style scoped>
.buttonGlass {
  box-sizing: border-box;
  overflow: hidden;
}
.buttonGlass::before {
  position: absolute;
  left: 0%;
  width: 20%;
  backdrop-filter: blur(2px);
  transform: skewX(-40deg) translateX(-3rem);
  background-color: white;
  opacity: 0.4 !important;
  z-index: 2;
  transition: 0.4s ease-out;
  border-radius: none !important;
}
.buttonGlass:hover::before {
  left: 100%;
  transform: skewX(-40deg) translateX(1rem);
}
</style>
